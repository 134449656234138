<template>
    <div class="showDoctorPage_container">
        <div class="showDoctorPage_sidebar">
            <img :src="getImage('banner')" alt="" >
            <div class="doctorPgae_sidebar_line"></div>
            <div class="showDoctorPage_custom_area global_full_screen">
                <div class="showDoctorPage_quotes  global_container_center" :style="getStyle('background')">
                    <img :src="require('@/assets/images/meeting/tag.png')" alt="" >
                </div>
                <div class="showDoctorPage_profile global_container_center">
                    <!-- <div class="showDoctorPage_profile_main"> -->
                        <div class="showDoctorPage_profile_block global_container_center" :style="getStyle('border-color')">
                            <!-- <img :src="require('@/assets/images/large.png')" alt="" > -->
                            <img :src="data.doctorHeadImg" :style="getStyle('border-color')" alt="" />
                        </div>
                    <!-- </div> -->
                    <div class="showDoctorPage_profile_border"></div>
                </div>
            </div>
        </div>
        <div class="showDoctorPage_content">
            <div class="showDoctorPage_quotes_text">
                <div class="showDoctorPage_quotes_text_block " :style="getStyle('border-color')">
                    <div class="showDoctorPage_match_quotations" :style="getStyle('color')">{{data.quotation}}</div>
                </div>
            </div>
            <div class="showDoctorPage_doctor_info">
                <div class="showDoctorPage_doctor_info_main">
                    <div class="showDoctorPage_doctor_info_name_block" :style="getStyle('border-color')">
                        <div class="showDoctorPage_doctor_info_name " :style="getStyle('color')">{{data.doctorName}}</div>
                    </div>
                    <div class="showDoctorPage_doctor_info_detail">
                        <div class="showDoctorPage_doctor_info_resume_title showDoctorPage_doctor_info_input">
                            <div class="showDoctorPage_doctor_info_input_block">
                                <img :src="getImage('doctor_name')" alt="">
                                <div class="showDoctorPage_doctor_info_text">{{data.resume}}</div>
                            </div>
                            <!-- <div class="showDoctorPage_doctor_info_resume_tip_text global_container_center">(职称*，包括主任医师、博导、硕导、教授等)</div> -->
                        </div>
                        <div class="showDoctorPage_doctor_info_resume_hospital showDoctorPage_doctor_info_input">
                            <div class="showDoctorPage_doctor_info_input_block">
                                <img :src="getImage('hospital')" alt="">
                                <div class="showDoctorPage_doctor_info_text">{{data.doctorHospital}}</div>
                            </div>
                        </div>
                        <div class="showDoctorPage_doctor_info_resume_department showDoctorPage_doctor_info_input" v-if="data.doctorDepartment">
                            <div class="showDoctorPage_doctor_info_input_block showDoctorPage_doctor_info_input_block_other">
                                <img :src="require('@/assets/images/meeting/doctor_name.png')" alt="" >
                                <div class="showDoctorPage_doctor_info_text">{{data.doctorDepartment}}</div>
                            </div>
                            <!-- <div class="showDoctorPage_doctor_info_resume_tip_text global_container_center">(科室)</div> -->
                        </div>
                        <div class="showDoctorPage_doctor_info_resume_dutuies showDoctorPage_doctor_info_input">
                            <div class="showDoctorPage_doctor_info_input_block showDoctorPage_doctor_info_input_block_other">
                                <img :src="require('@/assets/images/meeting/doctor_name.png')" alt="" >
                                <div class="showDoctorPage_doctor_info_text">{{data.resumeJob}}</div>
                            </div>
                            <!-- <div class="showDoctorPage_doctor_info_resume_tip_text global_container_center">(行政职务)</div> -->
                        </div>
                    </div>

                </div>

            </div>
            <div class="showDoctorPage_doctor_resume">
                <div class="showDoctorPage_doctor_resume_line">
                    <div class="showDoctorPage_doctor_resume_arrow" :style="getStyle('border-left-color')" v-for="(item,index) in new Array(3).fill(1)" :key="index"></div>
                    <div class="showDoctorPage_doctor_resume_line_item"  :style="getStyle('border-top-color')"></div>
                    <div class="showDoctorPage_doctor_resume_line_cycle" :style="getStyle('background')"></div>
                </div>
                <div class="showDoctorPage_doctor_resume_list">
                    <div v-for="(v,k) in data.resumeAcademic" class="showDoctorPage_doctor_resume_ul" :key="k">
                        <div class="showDoctorPage_doctor_resume_line_cycle_li">
                            <div class="showDoctorPage_doctor_resume_line_cycle"  :style="getStyle('background')"></div>
                        </div>
                        <span>{{v}}</span>
                    </div>
                    
                </div>

            </div>

        </div>
        <div class="global_statement_item global_statement_container" v-if="!projectList.includes(meetInfo.project_id)">
            <div class="global_statement">{{$config.statement}}</div>
        </div>
    </div>
</template>
<script>
import { ElInput } from 'element-plus'
export default {
    name:"showDoctorPage",
    props:{
        official:{
            type:Number,
            default:0
        },
        pageItem:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        meetInfo:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    components:{
        ElInput
    },
    data(){
        return {
            projectList:[36,35],
            data:{
                quotation:"",
                doctorHeadImg:"",
                doctorName:"",
                resume:"",
                doctorHospital:"",
                doctorDepartment:"",
                resumeJob:"",
                resumeAcademic:""
            },
            fileds:['quotation','doctorHeadImg','doctorName','resume','doctorHospital','doctorDepartment','resumeJob','resumeAcademic'],
            resume:"",
            theme:this.$config.theme
        }
    },
    created(){
        // this.pageItem.map(x=>{return x.class_content})
        this.initData()
    },
    methods:{
        initData(){
            if(this.official){
                this.data = {...this.pageItem}
                return;
            }
            Object.keys(this.data).forEach((v,k)=>{
                this.data[v] = this.pageItem.content[k].class_content;
                if(v == "resumeAcademic"){
                    this.data[v] = this.$tools.trimEnter(this.data[v]).split(/[,\n]/);
                }
            })
        },
        getItem(){
            let key = this.meetInfo?.main_color || "9e004b"
            // let key = "80b25f"
            return this.theme[key] 
        },
        getImage(name){
            let item = this.getItem()
            let prefix = item.name  || ""
            prefix = prefix ? `${prefix}_` : ''
            return require(`@/assets/images/meeting/${prefix}${name}.png`)
        },
        getStyle(attr){
            let item = this.getItem();
            return `${attr}:${item.color}`
        }
    }
}
</script>
<style>
.showDoctorPage_container{
    height:100%;
    width:100%;
    position: relative;
    display:flex;
    background: #fff;
}
.showDoctorPage_sidebar{
    width:22.4vw;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.showDoctorPage_content{
    flex: 1 0 0;
    padding:0 6vw;
    display: flex;
    flex-direction: column;
}
.showDoctorPage_sidebar img{
    width:21.33vw;
    height:100%;
    position: relative;
    z-index:2;
}
.doctorPgae_sidebar_line{
    width:3vw;
    height:100%;
    box-shadow: 0.5vw 0vw  .4vw .4vw rgba(230,230,230,.5);
}
.showDoctorPage_custom_area{
    position: absolute;
    left:0;
    top:0;
}
.showDoctorPage_quotes{
    height:11.4vw;
    width:11.4vw;
    background:#9e004b;
    border:0.8vw solid #fff;
    position: absolute;
    top:9.33vw;
    right:-5.7vw;
    border-radius:11.5vw;
    z-index:10;
}
.showDoctorPage_quotes img{
    height:8vw;
    width:auto;
}
.showDoctorPage_profile{
    height:32vw;
    width:32vw;
    position: absolute;
    top:25.46vw;
    right:-16vw;
    border-radius:16vw;
    z-index:20;
}
.showDoctorPage_profile_border{
    position: absolute;
    right:0;
    width:15.9vw;
    height:32vw;
    border-right:0.8vw solid #fff;
    border-top:0.8vw solid #fff;
    border-bottom:0.8vw solid #fff;
    border-radius: 0 15.9vw 15.9vw 0;
    box-shadow: 0.5vw 0vw  .4vw .4vw rgba(230,230,230,.5);
    z-index:0;
}
.showDoctorPage_profile_main{
    height:32vw;
    width:32vw;
    border-radius:16vw;
    border:0.8vw solid #fff;
    position: relative;
    z-index:40;
}
.showDoctorPage_profile_block{
    height:30.4vw;
    width:30.4vw;
    background:#fff;
    border-radius:15.2vw;
    border:1.6vw solid #9e004b;
    position: relative;
    z-index:40;
}
.showDoctorPage_profile img{
    height:24vw;
    width:24vw;
    border-radius:12vw;
    position: absolute;
    border:0.8vw solid #9e004b;
}
.showDoctorPage_quotes_text{
    height:23vw;
    padding-top:7vw;
    width:100%;
}
.showDoctorPage_quotes_text_block{
    height:16vw;
    width:100%;
    border-radius:1vw;
    border:1px solid #9e004b
}
.showDoctorPage_match_quotations{
    width: 100%;
    height: 100%;
    color: #9e004b;
    display: flex;
    align-items: center;
    padding: 0 3.6vw;
    font-size: 2.4vw;
    font-weight: 600;
    line-height:1.3;
}
.showDoctorPage_doctor_info{
    /* height:28vw; */
    display: flex;
    /* padding-right: 4vw; */
    justify-content: flex-end;
    margin-top:4vw;
    min-height:32vw;
}
.showDoctorPage_doctor_info_main{
    height:100%;
    width:50vw;
}
.showDoctorPage_doctor_info_detail{
    margin-top:2.33vw;
}
.showDoctorPage_doctor_info_input{
    display: flex;
    align-items: center;
    margin-bottom:2.33vw;

}
.showDoctorPage_doctor_info_input_block{
    display: flex;
    align-items: center;
}
.showDoctorPage_doctor_info_text{
    font-size: 2.4vw;
}
.showDoctorPage_doctor_info_input_block img{
    height:2.8vw;
    width:2.8vw;
    margin-right:1vw;
}
.showDoctorPage_doctor_info_resume_tip_text{
    color:#d9d9d9;
    font-size:2.4vw;
    flex:1 0 0;
    display:block;
    height:100%;
}
.showDoctorPage_doctor_info_name_block{
    border-bottom:0.66vw solid #9e004b;
}
.showDoctorPage_doctor_info_name{
    width: 100%;
    height: 4.8vw;
    line-height: 4.8vw;
    font-size:4vw !important;
    color: #9e004b;
    font-weight: 600;
}
.showDoctorPage_doctor_resume{
    flex:1 0 0;
    margin-top:2vw;
}
.showDoctorPage_doctor_resume_list{
    margin:3.7vw 0 0;
}
.showDoctorPage_doctor_resume_list li::marker{
    color: #9e004b;
}
.showDoctorPage_doctor_resume_ul{
    display: flex;
}
.showDoctorPage_doctor_resume_list{
    font-size:2.53vw;
    line-height: 3.2vw;
}
.showDoctorPage_doctor_resume_line{
    width:100%;
    display: flex;
    align-items: center;
}
.showDoctorPage_doctor_resume_arrow{
    height:0;
    width:0;
    border:1.33vw solid #9e004b;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;

}
.showDoctorPage_doctor_resume_line_item{
    height:0;
    flex:1 0 0;
    border-top:0.33vw dashed #9e004b
}
.showDoctorPage_doctor_resume_line_cycle{
    height:1.33vw;
    width:1.33vw;
    /* margin-left:1vw; */
    border-radius: 1.33vw;
    background: #9e004b;
}
.showDoctorPage_doctor_resume_line_cycle_li{
    margin-right:1vw;
    height:3.2vw;
    display: flex;
    align-items: center;
}
.showDoctorPage_doctor_info_input_block_other img{
    visibility: hidden;
}
</style>