<template>
  <div
    class="doctorPreview_container global_full_screen global_container_center"
  >
    <div class="doctorPrevview_main">
      <div class="doctorPrevview_docter_page">
        <ShowDocterPage
          v-if="pageItem"
          :meetInfo="meetInfo"
          :pageItem="pageItem"
        ></ShowDocterPage>
      </div>
      <div class="doctorPrevview_docter_tip">
        如需修改，请在专家【预览备课】前进入专家<span>【个人信息】</span>页面进行修改，并重新预览确认
      </div>
    </div>
  </div>
</template>
<script>
import ShowDocterPage from "@/components/page/meeting/show/docterPage";
export default {
  name: "doctorPreview",
  components: {
    ShowDocterPage,
  },
  data() {
    return {
      pageItem: null,
      meetInfo: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let url = this.$tools.getURL(this.$urls.admin.preview, {
        id: this.$route.query.id,
      });
      this.$axios.get(url).then((res) => {
        let data = res.data;
        this.pageItem = {
          content: data.page,
        };
        console.log(res);
        this.meetInfo.main_color = data.main_color;
        console.log(this.pageItem);
      });
    },
  },
};
</script>
<style>
.doctorPrevview_docter_page {
  width: 100vw;
  height: 100vw;
  /* background: red; */
}
.doctorPrevview_docter_tip {
  padding: 16vw 8vw 4vw;
}
.doctorPrevview_docter_tip span {
  color: red;
}
</style>